import "@postenbring/hedwig-css";
import { Accordion } from "@postenbring/hedwig-react";

export function ServiceSheetAccordion({ supplementaryServices, faq, localization }) {
    const services = supplementaryServices?.lists || [];
    const faqs = faq || [];

  return (
    <>
    {services.length > 0 && (
      <Accordion>
        {services.map((service, index) => (
          <Accordion.Item key={index}>
            <Accordion.Header>{service.title}</Accordion.Header>
            <Accordion.Content>
              <div className="hw-wysiwyg" dangerouslySetInnerHTML={{ __html: service.description }} />
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion>
    )}
    {faqs.length > 0 && (
      <div className="hw-block hw-block--mb-large hw-block--mt-large-1">
        <div className="hw-block hw-block--mb-small">
          <h2 className="hw-color-black">{localization.faq}</h2>
        </div>
      </div>
    )}

    {faqs.length > 0 && (
      <Accordion>
        {faqs.map((faqItem, index) => (
          <Accordion.Item key={index}>
            <Accordion.Header>{faqItem.question}</Accordion.Header>
            <Accordion.Content>
              <div className="hw-wysiwyg" dangerouslySetInnerHTML={{ __html: faqItem.answer }} />
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion>
    )}
  </>
  );
}